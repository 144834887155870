<template>
	<div class="controlsCarousel">
		<div
			:class="backClasses"
			class="controlsCarousel__back"
			@click="prev()"
		>
			<IconSax
				type="bold"
				name="arrow-circle-left"
			/>
		</div>
		<div
			:class="nextClasses"
			class="controlsCarousel__next"
			@click="next()"
		>
			<IconSax
				type="bold"
				name="arrow-circle-right"
			/>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		prev() {
			this.$emit("prev");
		},
		next() {
			this.$emit("next");
		},
	},
	props: {
		disableNext: {
			type: Boolean,
		},
		disablePrev: {
			type: Boolean,
		},
	},
	computed: {
		backClasses() {
			return {
				"controlsCarousel__back--disabled": this.disablePrev,
			};
		},
		nextClasses() {
			return {
				"controlsCarousel__next--disabled": this.disableNext,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.controlsCarousel {
	@include flex-row;
	cursor: pointer;

	&__back,
	&__next {
		transition: opacity 0.3s ease;
		::v-deep .svgCss svg {
			width: 32px;
			height: 32px;
			path {
				fill: var(--controls-prev-next);
			}
		}
		&:hover {
			opacity: 0.7;
		}

		&--disabled {
			cursor: not-allowed;
			opacity: 0.5;

			&:hover {
				opacity: 0.5;
			}
		}
	}
}
</style>
